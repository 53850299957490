import {
  UNREAD_COUNT_FAILURE,
  UNREAD_COUNT_REQUEST,
  UNREAD_COUNT_SUCCESS,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_SUCCESS,
  MARK_READ_FAILURE,
  MARK_READ,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILURE,
  MARK_READ_TICKET,
  MARK_READ_TICKET_FAILURE,
  MARK_UNREAD,
  MARK_UNREAD_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_SUCCESS,
  MARK_READ_NOTIFICATION,
  MARK_READ_NOTIFICATION_FAILURE,
  MARK_READ_NOTIFICATION_BY_OPEN_FAILURE,
  MARK_READ_NOTIFICATION_BY_OPEN,
  DELETE_NOTIFICATIONS,
  DELETE_NOTIFICATIONS_FAILURE
} from "./types";

const getTotalCount = (messages) => {
  if (messages.length === 0) return 0;
  const count = messages.reduce((count, { records }) => count + records.filter((record) => !record.isRead).length, 0);
  return count;
};

const setIsRead = (messages, ids) => {
  messages.forEach((item) => {
    item.records.forEach((record) => {
      if (ids.includes(record.notificationId)) {
        record.isRead = true;
      }
    });
  });
  return messages;
};

const setIsUnread = (messages, id) => {
  for (let item of messages) {
    for (let record of item.records) {
      if (id === record.notificationId) {
        record.isRead = false;
        return messages;
      }
    }
  }
};

const markReadNotifications = (notifications, ids) => {
  let count = 0;
  notifications.forEach((notification) => {
    if (ids.includes(notification._id) && !notification.isRead) {
      notification.isRead = true;
      count++;
    }
  });
  return { notifications, count };
};

const markReadNotificationByOpen = (notifications, id) => {
  return notifications.map((notification) => {
    if (notification._id === id) {
      return { ...notification, isRead: true };
    }
    return notification;
  });
};

const setISReadTicket = (messages, id, priv) => {
  let count = 0;
  const findIndex = messages.findIndex((msg) => msg._id === id);
  messages[findIndex].records.forEach((record) => {
    if (record.isPrivate === priv && record.isRead === false) {
      record.isRead = true;
      count++;
    }
  });
  return { messages, count };
};
const deleteMessages = (messages, ids) => {
  messages.forEach((item) => {
    item.records = item.records.filter((record) => !ids.includes(record.notificationId));
  });
  return messages;
};
const deleteNotifications = (notifications, ids) => {
  let unreadCount = 0;
  notifications = notifications.filter(({ _id, isRead }) => {
    if (!isRead) unreadCount++;
    return !ids.includes(_id);
  });
  return {
    notifications,
    unreadCount
  };
};
const initialState = {
  messages: [],
  unreadCount: 0,
  unreadMessages: 0,
  unreadNotifications: 0,
  notifications: []
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNREAD_COUNT_REQUEST:
    case GET_MESSAGES_REQUEST:
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case MARK_READ_TICKET: {
      const updatedData = setISReadTicket(state.messages, action.payload.ticketId, action.payload.privateComment);
      return {
        ...state,
        loading: false,
        messages: updatedData.messages,
        unreadCount: state.unreadCount - updatedData.count,
        unreadMessages: state.unreadMessages - updatedData.count
      };
    }
    case MARK_READ_NOTIFICATION: {
      const stateUpdate = markReadNotifications(state.notifications, action.payload);
      return {
        ...state,
        loading: false,
        notifications: stateUpdate.notifications,
        unreadNotifications: state.unreadNotifications - stateUpdate.count,
        unreadCount: state.unreadCount - stateUpdate.count
      };
    }
    case MARK_READ_NOTIFICATION_BY_OPEN: {
      return {
        ...state,
        loading: false,
        notifications: markReadNotificationByOpen(state.notifications, action.payload),
        unreadNotifications: state.unreadNotifications - 1,
        unreadCount: state.unreadCount - 1
      };
    }
    case MARK_READ: {
      const countchange = action.payload?.length ? action.payload.length : 1;
      return {
        ...state,
        loading: false,
        messages: setIsRead(state.messages, action.payload),
        unreadCount: state.unreadCount - countchange,
        unreadMessages: state.unreadMessages - countchange
      };
    }
    case MARK_UNREAD: {
      return {
        ...state,
        loading: false,
        messages: setIsUnread(state.messages, action.payload),
        unreadCount: state.unreadCount + 1,
        unreadMessages: state.unreadMessages + 1
      };
    }
    case DELETE_MESSAGE: {
      const countChange = action.payload.read ? 0 : action.payload?.payload?.length;
      return {
        ...state,
        loading: false,
        message: deleteMessages(state.messages, action.payload.payload),
        unreadCount: state.unreadCount - countChange,
        unreadMessages: state.unreadMessages - countChange
      };
    }
    case DELETE_NOTIFICATIONS: {
      const updatedRecords = deleteNotifications(state.notifications, action.payload);
      return {
        ...state,
        loading: false,
        notifications: updatedRecords.notifications,
        unreadNotifications: state.unreadNotifications - updatedRecords.unreadCount,
        unreadCount: state.unreadCount - updatedRecords.unreadCount
      };
    }
    case UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: action.payload.unreadCount,
        unreadMessages: action.payload.unreadMessages,
        unreadNotifications: action.payload.unreadNotifications,
        loading: false
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.messages,
        unreadMessages: getTotalCount(action.payload.messages)
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        unreadNotifications: action.payload.notifications.filter(({ isRead }) => !isRead).length
      };
    case UNREAD_COUNT_FAILURE:
    case GET_MESSAGES_FAILURE:
    case GET_NOTIFICATION_FAILURE:
    case MARK_READ_FAILURE:
    case MARK_UNREAD_FAILURE:
    case DELETE_MESSAGE_FAILURE:
    case MARK_READ_TICKET_FAILURE:
    case MARK_READ_NOTIFICATION_FAILURE:
    case MARK_READ_NOTIFICATION_BY_OPEN_FAILURE:
    case DELETE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default messagesReducer;
