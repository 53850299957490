export const UNREAD_COUNT_REQUEST = "UNREAD_COUNT_REQUEST";
export const UNREAD_COUNT_SUCCESS = "UNREAD_COUNT_SUCCESS";
export const UNREAD_COUNT_FAILURE = "UNREAD_COUNT_FAILURE";
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";
export const MARK_READ_FAILURE = "MARK_READ_FAILURE";
export const MARK_READ = "MARK_READ";
export const MARK_UNREAD_FAILURE = "MARK_UNREAD_FAILURE";
export const MARK_UNREAD = "MARK_UNREAD";
export const DELETE_MESSAGE_FAILURE = "DELETE_MESSAGE_FAILURE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const MARK_READ_TICKET_FAILURE = "MARK_READ_TICKET_FAILURE";
export const MARK_READ_TICKET = "MARK_READ_TICKET";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
export const MARK_READ_NOTIFICATION_FAILURE = "MARK_READ_NOTIFICATION_FAILURE";
export const MARK_READ_NOTIFICATION = "MARK_READ_NOTIFICATION";
export const MARK_READ_NOTIFICATION_BY_OPEN_FAILURE = "MARK_READ_NOTIFICATION_BY_OPEN_FAILURE";
export const MARK_READ_NOTIFICATION_BY_OPEN = "MARK_READ_NOTIFICATION_BY_OPEN";
export const DELETE_NOTIFICATIONS_FAILURE = "DELETE_NOTIFICATIONS_FAILURE";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
